<template>
  <v-card>
    <v-card-title>
      {{ $t('UpdateInfo') }}
    </v-card-title>

    <v-card-text>
      <v-form
        ref="registerForm"
        @submit.prevent="handleFormSubmit"
      >
        <v-text-field
          v-model="userdata.full_name"
          dense
          outlined
          :label="$t('Fullname')"
          :rules="[validators.required]"
          :placeholder="$t('Fullname')"
          hide-details="auto"
          class="mb-6"
          validate-on-blur
        ></v-text-field>
        <v-text-field
          v-model="userdata.phone"
          dense
          outlined
          type="tel"
          :label="$t('PhoneNumber')"
          hide-details="auto"
          class="mb-6"
          readonly
        >
        </v-text-field>

        <v-select
          v-model="userdata.gender"
          dense
          outlined
          :label="$t('Gender')"
          :placeholder="$t('Gender')"
          :items="[
            {text: $t('Male'), value: 1},
            {text: $t('Female'), value: 2},
            {text: $t('Other'), value: 0}
          ]"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-6"
          validate-on-blur
        ></v-select>

        <v-autocomplete
          v-model="userdata.year_of_birth"
          dense
          outlined
          :items="yearsOfBirth"
          :label="$t('YearOfBirth')"
          :placeholder="$t('YearOfBirth')"
          :rules="[validators.required]"
          hide-details="auto"
          class="mb-6"
          validate-on-blur
        ></v-autocomplete>

        <v-row justify="space-around">
          <v-col
            cols="6"
            md="3"
            class="text-center"
          >
            <Upload
              v-model="faceImageUpload"
              :auto-sizing="true"
              :initial-image="userdata.face_image_url"
            />
            <div>{{ $t('FaceImage') }}</div>
          </v-col>
          <v-col
            cols="6"
            md="3"
            class="text-center"
          >
            <Upload
              v-model="faceMaskImageUpload"
              :auto-sizing="true"
              :initial-image="userdata.face_mask_image_url"
            />
            <div>{{ $t('FaceWithMaskImage') }}</div>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-btn
              block
              color="primary"
              type="submit"
              :loading="loading"
            >
              {{ $t('Submit') }}
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
              outlined
              block
              @click="$router.push({ name: 'user-profile' })"
            >
              {{ $t('Cancel') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, alphaValidator } from '@core/utils/validation'
import { ref } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import Upload from '@/components/Upload/Upload.vue'
import UploadService from '@/services/UploadService'
import store from '@/store'

import AccountService from '@/services/AccountService'

export default {
  components: { Upload },
  setup() {
    const registerForm = ref(null)
    const { router } = useRouter()
    const userdata = ref({ ...store.getters['auth/currentUser'] })
    const faceImageUpload = ref({})
    const faceMaskImageUpload = ref({})
    let faceImageId = null
    let faceMaskImageId = null
    const loading = ref(false)

    const handleFormSubmit = async () => {
      const isFormValid = registerForm.value.validate()

      if (!isFormValid) return

      loading.value = true

      await faceImageUpload.value.asyncBase64('image/jpeg', 0.8).then(async base64 => {
        await UploadService.uploadFile({
          note: 'FaceImage',
          files: [base64],
          is_face: true,
        })
          .then(res => {
            faceImageId = res.data?.data?.files?.[0]?.id_file
          })
          .catch(error => {
            console.error(error)

            store.commit('snackbar/show', {
              message: error.message,
              type: 'error',
            })
          })
      })

      await faceMaskImageUpload.value.asyncBase64('image/jpeg', 0.8).then(async base64 => {
        await UploadService.uploadFile({
          note: 'faceMaskImage',
          files: [base64],
          is_face: true,
        })
          .then(res => {
            faceMaskImageId = res.data?.data?.files?.[0]?.id_file
          })
          .catch(error => {
            console.error(error)

            store.commit('snackbar/show', {
              message: error.message,
              type: 'error',
            })
          })
      })

      await AccountService.updateUserInfo(userdata.value.id_user, {
        full_name: userdata.value.full_name,
        gender: userdata.value.gender,
        year_of_birth: userdata.value.year_of_birth,
        face_image: faceImageId.toString(),
        face_mask_image: faceMaskImageId.toString(),
      })
        .then(() => {
          store.dispatch('auth/getUserInfo')
          store.commit('snackbar/show', {
            type: 'success',
            message: 'Update Successfully',
          })
          router.push({ name: 'user-profile' })
        })
        .catch(error => {
          store.commit('snackbar/show', {
            message: error.message,
            type: 'error',
          })
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      loading,
      userdata,
      faceImageUpload,
      faceMaskImageUpload,
      handleFormSubmit,
      validators: {
        required,
        alphaValidator,
      },

      // Template Refs
      registerForm,
    }
  },
}
</script>
